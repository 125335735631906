import * as React from 'react';
import { IconButton } from '@fluentui/react';
import sanitize from 'sanitize-html';
import './settlementCalculator.css';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
	toggleSection: (payee: string) => void;
	showFeesExplained: any;
	statementType: string,
	feesExplainedDescription: string
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

interface FeesExplainedDescription {
	StatementType: string,
	Enabled: boolean,
	Description: string
}

export default class FeesExplained extends React.Component<AppProps, AppStates> {
	render(): JSX.Element {
		const { showFeesExplained: showFeesExplained } = this.props;

		let feesExplained: FeesExplainedDescription[] = JSON.parse(this.props.feesExplainedDescription);
		let description = feesExplained.find(f => f.StatementType === this.props.statementType);

		return (
			<div className="section">
				<div className="right-align-section-header">
					Show/Hide Fees Explained
					<IconButton
						className="button show-hide-button"
						data-automation-id="save_button"
						onClick={() => this.props.toggleSection("FeesExplained")}
						allowDisabledFocus={true}
						iconProps={{
							iconName: showFeesExplained
								? "CaretDownSolid8"
								: "CaretUpSolid8",
							style: { fontSize: 10 },
						}} />
				</div>
				{showFeesExplained && (description?.Enabled ?? false) && (
					<div>
						<div className="ms-Grid-row additional-header">
							<div className="ms-Grid-col ms-sm8 ms-smPush2">
								<b className="header-text">FEES EXPLAINED</b>
							</div>
						</div>

						<div dangerouslySetInnerHTML={{
							__html: sanitize(description?.Description ?? "",
								{
									allowedTags: ['p', 'span', 'div', 'a', 'br'],
									allowedAttributes: {
										'p': ["style"],
										'span': ["style"],
										'div': ['style'],
										'a': ["href", "target"]
									},
									allowedStyles: {
										'*': {
											// Match HEX and RGB
											'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
											'text-align': [/^left$/, /^right$/, /^center$/],
											// Match any number with px, em, or %
											'font-size': [/^\d+(?:px|em|%)$/],
											'font-weight': [/^bold$/],
											'padding': [/^\d+(?:px|em|%)$/]
										}
									}
								})
						}} />
					</div>)}
			</div>
		)
	}
}