import * as React from "react";
import { connect } from "react-redux";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { AppState } from "app.types";
import { apportionmentOption, ModalIDs, periodOptions } from "containers/calculators/settlement/common";
import {
    GetModalToShow,
    CalculateApportionmentValue,
    formatToLocaleDateString,
    renderSwitch, paymentStatus,
    toFormatLocalString,
    showAdditionalFields,
    getFundType,
    getSourceOfFund,
    QldVendorSellerUtils,
    getNumberOfDays,
    daysOfTheYear,
    isQld
} from "./Helper";
interface IMapStateToProps {
    stateOfCountry: string;
}

interface IMapDispatchToProps { }

interface IProps {
    item: any;
    index: number;
    key: string;
    isVendor: boolean;
    additionalInfo: any;
    toggleWaterUsage?: (index: number) => void; // to match to the index set by the calculation function
    adjustmentDate?: Date;
    showModal: (modalID: ModalIDs, additionalInfo: any) => void;
    payeeCheckboxHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    additionalRequirementCheckboxHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    conveyType: string | undefined;
    draggable: boolean
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;

type AppStates = {};

export class SettlementInfo extends React.Component<AppProps, AppStates> {

    private getUnitTotal(): JSX.Element | null {

        let { item: info, additionalInfo } = this.props;

        let totalRunnungBalance = 0;
        if (this.props.conveyType === "SettlementStatement") {
            totalRunnungBalance = additionalInfo["contractBalance"] + info.total;
        }
        else {
            totalRunnungBalance = info.total;
        }

        return (
            <div>
                <div className="ms-Grid-col ms-sm12 separator">--------------</div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <b>
                            {totalRunnungBalance && toFormatLocalString(totalRunnungBalance, "en-AU", 2, 2)}
                        </b>
                    </div>
                </div>
            </div>
        );
        return null;
    }

    private getGst(): JSX.Element | null {
        let { item: info } = this.props;

        // display gst regardless of advanced calc is enabled or not, in case a advanced calc is "downgraded" to basic calc
        return info.gst ?
            <div>
                GST: {info.gst}
            </div > : <div />
    }

    public render(): JSX.Element {
        const { item: info, index, stateOfCountry, isVendor, additionalInfo, conveyType } = this.props;
        let { result } = info;
        let paidUnpaidStatus = info.type === ModalIDs.addUpdateHeader ? false : (isQld(stateOfCountry) && info.value['status'] !== undefined)
            ? paymentStatus(info.value['status']) + " "
            : (info.value['status'] !== undefined && info.type === ModalIDs.additionalRequirements)
                ? paymentStatus(info.value['status']) + " "
                : "";

        switch (info.type) {
            case ModalIDs.contractPrice:
                let IsTypeSettlementStatement = conveyType && conveyType === "SettlementStatement" ? true : false;
                return (
                    <div>
                        <div className={IsTypeSettlementStatement ? "" : "ms-Grid-row state-row"}>
                            <div className={IsTypeSettlementStatement ? "" : "ms-Grid-col ms-sm2"}></div>

                            <div className={IsTypeSettlementStatement ? "ms-Grid-col ms-sm12" : "ms-Grid-col ms-sm10"} id={IsTypeSettlementStatement ? "settlementThreeBorder" : "settlementBorder"}>
                                <div className="settlement-border">
                                    <div className="ms-Grid-row heading-row settlement-background">
                                        <b>SETTLEMENT {this.props.conveyType === "SettlementStatement" ? 'ADJUSTMENTS' : 'FIGURES'}</b>
                                    </div>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row detail-row">
                                            <div className="ms-Grid-col ms-sm6">
                                                <b>Contract Price</b>
                                            </div>
                                            <div className={"ms-Grid-col ms-sm6 price-info " + (isVendor ? "ms-smPush6" : "")} data-cy={"adjustment_result_" + index}>
                                                {toFormatLocalString(info.value["price"], "en-AU", 2, 2)}
                                            </div>
                                        </div>

                                        {info.value["deposit"] > 0 && (
                                            <div className="ms-Grid-row detail-row">
                                                <div className="ms-Grid-col ms-sm6">
                                                    <b>Less Deposit</b>
                                                </div>
                                                <div className={"ms-Grid-col ms-sm6 price-info " + (isVendor ? "ms-smPush6" : "")} >
                                                    {toFormatLocalString(info.value["deposit"], "en-AU", 2, 2)}
                                                </div>
                                            </div>
                                        )}

                                        <div>
                                            <div className="ms-Grid-col ms-sm12 separator">-------------------</div>

                                            <div className="ms-Grid-row" dir="rtl">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <b>
                                                        {additionalInfo && toFormatLocalString(additionalInfo.contractBalance, "en-AU", 2, 2)}
                                                    </b>
                                                </div>
                                            </div>
                                        </div>

                                        {!IsTypeSettlementStatement &&
                                            <>
                                                <div className="ms-Grid-row detail-row">
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <b>Plus/Less/Adjustments</b>
                                                    </div>
                                                    <div className={"ms-Grid-col ms-sm6 price-info " + (isVendor ? "ms-smPush6" : "")} >
                                                        {toFormatLocalString(additionalInfo.sumOfPlusLessAdjustmentAmount, "en-AU", 2, 2)}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row detail-row">
                                                    <div className="ms-Grid-col ms-sm12 separator">-------------------</div>
                                                    <div className="ms-Grid-col ms-sm6 ms-smPush4">
                                                        <b>BALANCE DUE TO THE {QldVendorSellerUtils(this.props.stateOfCountry).toUpperCase()} AT SETTLEMENT</b>
                                                    </div>
                                                    <div className={"ms-Grid-col ms-sm6 price-info " + (isVendor ? "ms-smPush6" : "")} >
                                                        <b> {toFormatLocalString(additionalInfo.balanceDueToTheVendorAtSettlement, "en-AU", 2, 2)}
                                                        </b>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.addUpdateHeader:
                return (
                    <div className="adjustment-header">
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => this.props.showModal(ModalIDs.addUpdateHeader, { index, groupName: info.groupName })}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm11">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                                <span className="adjustment-header-text">{info.groupName}</span>
                            </div>
                        </div>
                    </div >
                )

            case ModalIDs.dischargeFee:
            case ModalIDs.releaseFee:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => { GetModalToShow(this.props.item["type"], index, this.props.showModal) }}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm8">
                                            <b>{paidUnpaidStatus} {this.props.item["type"]}</b>
                                            <div>
                                                {toFormatLocalString(info.value["mortgages"], "en-AU", 0, 0)}
                                                {" "}
                                                x Discharge of Mortgage @{" "}
                                                $
                                                {toFormatLocalString(info.value["each"], "en-AU", 2, 2)}
                                                {" "}
                                            </div>
                                        </div>

                                        <div className={"ms-Grid-col ms-sm2 price-info " + (!isQld(stateOfCountry) ? "ms-smPull1" : "ms-smPush2")} data-cy={"adjustment_result_" + index} >
                                            {toFormatLocalString(result, "en-AU", 2, 2)}
                                            {isQld(stateOfCountry) && this.getUnitTotal()}
                                            {this.getGst()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.recycledWater:
            case ModalIDs.waterUsage:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => { GetModalToShow(this.props.item["type"], index, this.props.showModal) }}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm8">
                                            <b>{paidUnpaidStatus + info.type}</b>
                                            <div>(see calculation following)</div>
                                            <Checkbox
                                                label={"Show " + info.type}
                                                onChange={() => this.props.toggleWaterUsage!(index)}
                                            />
                                        </div>
                                        <div className={"ms-Grid-col ms-sm2 price-info " + (!isQld(stateOfCountry) ? "ms-smPull1" : "ms-smPush2")}>
                                            {toFormatLocalString(result, "en-AU", 2, 2)}
                                            {isQld(stateOfCountry) && this.getUnitTotal()}
                                            {this.getGst()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                );

            case ModalIDs.defaultInterest:
            case ModalIDs.penaltyInterest:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => GetModalToShow(this.props.item["type"], index, this.props.showModal)}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm10">
                                            <b>{paidUnpaidStatus + info.type}</b>
                                            <div>
                                                from{" "}
                                                {info.value["from"] === null
                                                    ? ""
                                                    : formatToLocaleDateString(info.value["from"])}{" "}
                                                to{" "}
                                                {info.value["to"] === null
                                                    ? ""
                                                    : formatToLocaleDateString(info.value["to"])}{" "}
                                                - &nbsp;{info.value["days"]} days @ {info.value["rate"]}
                                                %
                                            </div>
                                        </div>
                                        <div className={"ms-Grid-col ms-sm2 price-info" + (isVendor ? "ms-smPush2" : "")} data-cy={"adjustment_result_" + index}>
                                            {toFormatLocalString(result, "en-AU", 2, 2)}
                                            {isQld(stateOfCountry) && this.getUnitTotal()}
                                            {this.getGst()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.otherAdjustmentLegacy:
            case ModalIDs.otherAdjustment:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => this.props.showModal(ModalIDs.otherAdjustment, { index })}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm10">
                                            <b>{paidUnpaidStatus + (info.value["adjustment_type"] ? info.value["adjustment_type"] : info.value["description"])}</b>
                                        </div>
                                        <div className="ms-Grid-col ms-sm8">
                                            {info.value["adjustment_type"] ? info.value["description"] : ""}
                                        </div>
                                        {(info.value["status"] === "less" && !isQld(stateOfCountry))
                                            ? (<div className="ms-Grid-col ms-sm4 ms-smPull3 price-info" data-cy={"adjustment_result_" + index} >
                                                {toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                                {this.getGst()}
                                            </div>)
                                            : (<div className="ms-Grid-col ms-sm4 price-info" data-cy={"adjustment_result_" + index}>
                                                {toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                                {isQld(stateOfCountry) && this.getUnitTotal()}
                                                {this.getGst()}
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.fee:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => this.props.showModal(ModalIDs.fee, { index })}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"fee_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm8">
                                            <b>{info.value["description"]}</b>
                                        </div>
                                        {(info.value["status"] === "less" && isVendor === false) ||
                                            (info.value["status"] !== "less" && isVendor === true) ? (
                                            <div className="ms-Grid-col ms-sm2 ms-smPush2 price-info">
                                                {toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                            </div>
                                        ) : (
                                            <div className="ms-Grid-col ms-sm2 price-info">
                                                {toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                                {isQld(stateOfCountry) && this.getUnitTotal()}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.additionalRequirements:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() =>
                                        this.props.showModal(ModalIDs.additionalRequirements, {
                                            index,
                                        })
                                    }
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm9">
                                            {paidUnpaidStatus + info.value["description"]}
                                        </div>

                                        <div className="ms-Grid-col ms-sm2 price-info">
                                            {toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                        </div>

                                        <div className="ms-Grid-col ms-sm1 right-align">
                                            <input type="checkbox" id={index.toString()} checked={info.isChecked || false} onChange={(event) => this.props.additionalRequirementCheckboxHandler(event)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.payeeDetails:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => this.props.showModal(ModalIDs.payeeDetails, { index })}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm9">
                                            <div className="ms-Grid-row detail-row">
                                                <div className="ms-Grid-col ms-sm1">
                                                    {index + 1}.
                                                </div>
                                                <div className="ms-Grid-col ms-sm10">
                                                    {info.value["description"]}
                                                    <br />
                                                    {getFundType(info.value["fundType"])}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ms-Grid-col ms-sm2 price-info">
                                            {isNaN(info.value["amount"]) ? info.value["amount"] : toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                        </div>
                                        <div className="ms-Grid-col ms-sm1 right-align">
                                            <input type="checkbox" id={index.toString()} checked={info.isChecked || false} onChange={(event) => this.props.payeeCheckboxHandler(event)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.sourceOfFunds:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => this.props.showModal(ModalIDs.sourceOfFunds, { index })}
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm8">
                                            {index + 1}. {getSourceOfFund(info.value["sourceOfFunds"].toString())}
                                        </div>
                                        <div className="ms-Grid-col ms-sm4 price-info">
                                            {toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case ModalIDs.ourRequirements:
                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() =>
                                        this.props.showModal(ModalIDs.ourRequirements, { index })
                                    }
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm12">
                                            <b>{info.value["detail"]}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                let july1st = new Date(info.value["from"] ? (info.value["from"] as Date).getFullYear() : 2000, 6, 1); // month is index hence month no - 1
                let june30th = new Date(info.value["from"] ? (info.value["from"] as Date).getFullYear() : 2000, 5, 30);

                const qldPricePeriodText = (from: Date | null, to: Date | null): string => `${info.value["method"] === "daily-average" ? "per day " : ""}for the period ${from === null ? "" : formatToLocaleDateString(from)} to ${to === null ? "" : formatToLocaleDateString(to)}`
                const periodText = (from: Date | null, to: Date | null, days: number | null): string => `${from === null ? "" : formatToLocaleDateString(from)} to ${to === null ? "" : formatToLocaleDateString(to)} - ${days === null ? getNumberOfDays(Math.abs((to ?? new Date()).valueOf() - (from ?? new Date()).valueOf())) + 1 : days} days`

                return (
                    <div>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                    className="topaz-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() => GetModalToShow(this.props.item["type"], index, this.props.showModal) // dynamically binding click event because, for different modals this code block is common
                                    }
                                    allowDisabledFocus={true}
                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                    data-cy={"adjustment_info_" + index}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1">
                                <Icon
                                    iconName={this.props.draggable ? "ChevronUnfold10" : ""}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm8">
                                            <b>
                                                {info.type === "Other Adjustment Date" || info.type === "Other Adjustment - Date Period"
                                                    ? paidUnpaidStatus + (info.value["adjustment_type"] ? info.value["adjustment_type"] : info.value["description"])
                                                    : paidUnpaidStatus + info.type}
                                            </b>

                                            <div>{info.type === "Other Adjustment Date" || info.type === "Other Adjustment - Date Period" ? info.value["adjustment_type"] ? info.value["description"] : "" : ""}</div>
                                            {!isQld(stateOfCountry) ? (
                                                <div>
                                                    For period{" "}
                                                    {
                                                        parseFloat(info.value["increasedFee"]) > 0 ? (
                                                            <>
                                                                {periodText(info.value["from"], june30th, null)} <br />
                                                                {periodText(july1st, info.value["to"], null)} <br />
                                                            </>

                                                        ) : (
                                                            <>
                                                                {periodText(info.value["from"], info.value["to"], null)} <br />
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (showAdditionalFields(info.type) && info.value["method"] === "daily-average")
                                                            ? '' : '$' + toFormatLocalString(info.value["amount"], "en-AU", 2, 2)
                                                    }
                                                    {" "} {info.value["status"] === "unpaid"
                                                        ? "Unpaid"
                                                        : info.value["status"] === "adjust-as-paid"
                                                            ? "Adjusted as Paid"
                                                            : "Paid"}
                                                    <br />
                                                    {CalculateApportionmentValue(info)}
                                                    {" "}
                                                    {info.value["ctsOption"] !== undefined && info.value["ctsOption"] != apportionmentOption.doNotApportion ? <br /> : ''}
                                                    {info.value["status"] === "unpaid" ? (
                                                        ((info.type === ModalIDs.rent || info.type === ModalIDs.licenceFees) && (info.value["adjustmentPeriod"] === periodOptions.Week || info.value["adjustmentPeriod"] === periodOptions.Month)) ?
                                                            <span>
                                                                {QldVendorSellerUtils(this.props.stateOfCountry)} allows {info.value["adjustDays"]} {info.value["adjustmentPeriod"]?.toLowerCase() ?? 'day'}s
                                                                {(showAdditionalFields(info.type) && info.value["method"] === "daily-average")
                                                                    ? ' @' + toFormatLocalString(info.value["averageDailyAmount"], "en-AU", 1, 5) + ' per day' : ''}
                                                            </span> :
                                                            <span>
                                                                {QldVendorSellerUtils(this.props.stateOfCountry)} allows {info.value["adjustDays"]} days
                                                                {(showAdditionalFields(info.type) && info.value["method"] === "daily-average")
                                                                    ? ' @' + toFormatLocalString(info.value["averageDailyAmount"], "en-AU", 1, 5) + ` per ${info.value["adjustmentPeriod"]?.toLowerCase() ?? 'day'}s` : ''}
                                                            </span>
                                                    ) : (
                                                        ((info.type === ModalIDs.rent || info.type === ModalIDs.licenceFees) && (info.value["adjustmentPeriod"] === periodOptions.Week || info.value["adjustmentPeriod"] === periodOptions.Month)) ?
                                                            <span>
                                                                Purchaser allows {info.value["adjustDays"]} {info.value["adjustmentPeriod"]?.toLowerCase() ?? 'day'}s
                                                                {(showAdditionalFields(info.type) && info.value["method"] === "daily-average")
                                                                    ? ' @ $' + toFormatLocalString(info.value["averageDailyAmount"], "en-AU", 1, 5) + ` per ${info.value["adjustmentPeriod"]?.toLowerCase() ?? 'day'}s` : ''}
                                                            </span> :
                                                            <span>
                                                                Purchaser allows {info.value["adjustDays"]} days
                                                                {(showAdditionalFields(info.type) && info.value["method"] === "daily-average")
                                                                    ? ' @ $' + toFormatLocalString(info.value["averageDailyAmount"], "en-AU", 1, 5) + ' per day' : ''}
                                                            </span>
                                                    )}{" "}
                                                    <br />
                                                    For period {formatToLocaleDateString(info.value["from"])} to{" "}
                                                    {info.value["to"] === null ? "" : formatToLocaleDateString(info.value["to"])}
                                                </div>
                                            ) : (
                                                <div>
                                                    ${(showAdditionalFields(info.type) && info.value["method"] === "daily-average") ? toFormatLocalString(info.value["averageDailyAmount"], "en-AU", 1, 5) : toFormatLocalString(info.value["amount"], "en-AU", 2, 2)}
                                                    {" "}
                                                    {(info.value["adjustmentPeriod"] && info.value["adjustmentPeriod"] === periodOptions.Daily && renderSwitch(info.type)) ?
                                                        <>
                                                            per annum{" "}
                                                            <br />
                                                            Paid To: {info.value["to"] === null ? "" : formatToLocaleDateString(info.value["to"])}
                                                            <br />
                                                            {CalculateApportionmentValue(info)}
                                                            {info.value["ctsOption"] && info.value["ctsOption"] != apportionmentOption.doNotApportion ? <br /> : ''}
                                                            Proportion being {info.value["days"]} / {daysOfTheYear((info.value["to"] as Date | undefined)?.getFullYear() ?? 0)} days
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                parseFloat(info.value["increasedFee"]) > 0 ? (
                                                                    <>
                                                                        {qldPricePeriodText(info.value["from"], june30th)}
                                                                        <br />
                                                                        ${info.value["increasedFee"]}{" "}
                                                                        {qldPricePeriodText(july1st, info.value["to"])}
                                                                    </>
                                                                ) : (
                                                                    <>{qldPricePeriodText(info.value["from"], info.value["to"])}</>
                                                                )
                                                            }

                                                            <br />
                                                            {CalculateApportionmentValue(info)}
                                                            {info.value["ctsOption"] && info.value["ctsOption"] != apportionmentOption.doNotApportion ? <br /> : ''}
                                                            Proportion being {info.value["adjustDays"] == 0 || info.value["method"] === "daily-average" ? "" : `${info.value["adjustDays"]} / `}
                                                            {info.value["proportionDays"] ?? getNumberOfDays(info.value["to"] - info.value["from"]) + 1} days
                                                        </>
                                                    }
                                                </div>
                                            )}
                                        </div>

                                        {isQld(stateOfCountry) ?
                                            <div className="ms-Grid-col ms-sm4 price-info" data-cy={"adjustment_result_" + index} >
                                                {toFormatLocalString(result, "en-AU", 2, 2)}
                                                {isQld(stateOfCountry) && this.getUnitTotal()}
                                                {this.getGst()}
                                            </div>
                                            :
                                            (info.value["status"] === "unpaid" && isVendor === false) || (info.value["status"] !== "unpaid" && isVendor === true) ? (
                                                <div className="ms-Grid-col ms-sm4 ms-smPull3 price-info" data-cy={"adjustment_result_" + index} >
                                                    {toFormatLocalString(result, "en-AU", 2, 2)}
                                                    {this.getGst()}
                                                </div>
                                            ) : (
                                                <div className="ms-Grid-col ms-sm4 price-info" data-cy={"adjustment_result_" + index} >
                                                    {toFormatLocalString(result, "en-AU", 2, 2)}
                                                    {this.getGst()}
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }
}

const mapStateToProps = (state: AppState): IMapStateToProps => {
    return {
        stateOfCountry: state.settlementInfo.state,
    };
};

export default connect(mapStateToProps)(SettlementInfo);
